<template>
  <div class="viewContainer inventory">
    <h1>{{ $t('inventory.inventories') }}</h1>
    <CreateInventoryModal @inventoryUpdated="reloadInventories" @inventoryCreated="reloadInventories" />
    
    <div class="inventories-head">
        
      <FrontendFilterSearch :placeholder="$t('inventory.search')" />
      <button v-if="!isInventoryOnly" @click="addInventory()" class="iconButton add">{{ $t('inventory.add') }}</button>
    </div>
    <InventoriesContent ref="inventoriesContent" />
  </div>

</template>
<script>
import CreateInventoryModal from '@/components/modals/inventory/CreateInventoryModal.vue'
import InventoriesContent from '../components/inventory/InventoriesContent.vue';
import FrontendFilterSearch from '../components/inputs/FrontendFilterSearch.vue';
import { mapGetters } from 'vuex'

export default {
  name: 'Inventories',
  data() {
    return {
    }
  },
  created() {
  },
  components: {
    CreateInventoryModal,
    InventoriesContent,
    FrontendFilterSearch

  },
  methods: {

    reloadInventories() {
      this.$refs.inventoriesContent.loadInventories()
    },
    addInventory() {
      this.$bvModal.show('inventory-modal')
    },
  },
  computed: {
    ...mapGetters(['isInventoryOnly'])
  }
}
</script>
<style scoped>
.inventories-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

button.iconButton {
  min-width: 156px;
}

</style>