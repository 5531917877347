<template>
  <div>
    <b-dropdown size="lg" toggle-class="text-decoration-none" class="m-2 dot-menu" no-caret>
      <template #button-content>
        <b-icon icon="three-dots-vertical"></b-icon>
      </template>
      <slot v-on:action="triggerAction"></slot>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'DotMenu',
  methods: {
    triggerAction(actionName) {
      this.$emit(actionName);
    },
  },
}
</script>
<style scoped>

</style>