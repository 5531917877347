<template>

  <basic-modal :modal-id="'inventory-modal'" :size="'md'" @reset="resetModal" :confirm-button-text="$t('general.buttons.add')"
    :confirm-action="saveInventory">

    <template #title>
      <div v-if="isEditMode"> {{ $t('inventory.edit') }}</div>
      <div v-else>
        {{ $t('inventory.new') }}
      </div>
    </template>
    <b-row>
      <b-col>
        <label for="name">{{ $t('inventory.name') }}</label>
        <b-form-input id="name" v-model="name" :placeholder="$t('inventory.nameOfInventory')"></b-form-input>
      </b-col>
      <b-col>
        <label for="typeDropdown">{{ $t('inventory.type') }}</label>

        <b-dropdown id="typeDropdown" :text="typeText(selectedType)" class="typeDropdown">
          <b-dropdown-item v-for=" option in inventoryTypeOptions" :key="option.value" :value="option.value" 
            @click="setType(option)">
            {{ option.text }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col class="startDatePicker">
        <label for="startDatePicker">{{ $t('inventory.startDate') }}</label>
        <b-form-datepicker :locale="$i18n.locale" id="startDatePicker" v-model="startDate"></b-form-datepicker>
      </b-col>
    </b-row>
    <b-row class="mt-m">
      <b-col>
        <label for="responsibleInventoryUser">{{ $t('inventory.responsibleUsers') }}</label>
        <b-form-checkbox-group id="responsibleInventoryUsers" v-model="selectedUserOptions" class="user-options-container">
          <b-form-checkbox class="user-option" v-for="(option, index) in userOptions" :key="index"
            :value="option.value">
            {{ option.text }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-col>
    </b-row>
    <template #footer>
      <b-row class="w-100" v-if="loading">
        <b-col cols="12" class="text-center">
          <div class="spacer m"></div>
          <LoadingAnimation />
        </b-col>
      </b-row>
      <div v-if="!loading">
        <div class="flex-row justify-between gap-m" v-if="isEditMode">
          <Button class="w-m" :onClick="saveInventory" :buttonText="$t('inventory.save')" :disabled="buttonDisabled"
            :primary="true">
          </Button>
          <Button class="w-m" :onClick="closeModal" :buttonText="$t('general.buttons.cancel')" :disabled="false"
            :primary="false">
          </Button>
        </div>

        <Button v-else class="w-m" :onClick="saveInventory" :buttonText="$t('inventory.add')" :disabled="buttonDisabled"
          :primary="true">
        </Button>
      </div>

    </template>

  </basic-modal>

</template>
<script>
import BasicModal from '@/components/modals/BasicModal.vue'
import DataService from '@/services/StrapiService'
import { mapGetters } from 'vuex'
import Button from '@/components/buttons/Button.vue'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import { isInventoryOnly } from '@/utils/functions'


export default {
  name: 'CreateInventoryModal',
  data() {
    return {
      loading: false,
      isEditMode: false,
      name: '',
      startDate: new Date(),
      userOptions: [],
      selectedUserOptions: [],
      selectedType: null,
      inventory: {},
      inventoryTypeOptions: [
        { value: 'dueDate', text: this.$t('inventory.dueDate') }
      ]
    }
  },
  components: {
    BasicModal,
    Button,
    LoadingAnimation
  },
  created() {
    this.mapUserOptions()
  },
  mounted() {
    this.selectedType = this.inventoryTypeOptions[0]
    this.$root.$on('editInventory', (inventory) => {
      if (inventory && inventory.id) {
        this.inventory = inventory;
        this.name = inventory.name;
        this.inventoryState = inventory.inventoryState;
        this.inventoryScope = inventory.inventoryScope;
        /*
        this.selectedType = this.inventoryTypeOptions.find((type) => type.value === inventory.inventoryType);
        */
        this.selectedUserOptions = inventory.inventory_users.map((user) => user.users_permissions_user.id);
        this.startDate = inventory.startDate;
        this.inventory_users = inventory.inventory_users;
        this.isEditMode = true;
      }
    });
  },

  beforeDestroy() {
    this.resetModal()
    this.$root.$off('editInventory')
  },
  computed: {
    ...mapGetters(['getVenue']),
    buttonDisabled() {
      return !this.name || !this.selectedType || !this.selectedType.value
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('inventory-modal')
    },
    typeText(type) {
      if (type) {
        return type.text
      } else if (this.selectedType){
        return this.selectedType.text
      } else {
        return this.$t('inventory.type')
      }
    },
    setType(type) {
      this.selectedType = type
    },
    resetModal() {
      this.inventory = null
      this.isEditMode = false
      this.name = ''
      this.startDate = new Date()
      this.selectedUserOptions = []
    },
    mapUserOptions() {
      const users = this.getVenue.attributes.users
      console.log('users :>> ', users);
      const inventoryUsers = users.data.filter((user) => {
        return isInventoryOnly(user.role)
      })

      inventoryUsers.map((user) => {
        this.userOptions.push({
          value: user.id,
          text: user.username
        })
      })

    },

    async saveInventory() {
      this.loading = true
      if (this.isEditMode) {
        await this.updateInventory()
      } else {
        await this.addInventory()
      }
      this.loading = false
      this.$bvModal.hide('inventory-modal')

    },
    async updateInventory() {
      const inventory_users = await this.createInventoryUsers(true)
      const inventory = {
        name: this.name,
        startDate: this.startDate,
        inventoryType: this.selectedType.value,
        inventory_users: inventory_users
      }
      DataService.updateInventory(this.inventory.id, inventory).then(() => {
        this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.successUpdate"), variant: "success" });
        this.$emit('inventoryUpdated')
      }).catch((err) => {
        console.log(err)
        this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.errorUpdate"), variant: "danger" });
      })

    },
    async createInventoryUsers(checkForExistingUsers) {
      var inventory_users = []
      for (let i = 0; i < this.selectedUserOptions.length; i++) {
        let userAlreadyFound = false
        if (checkForExistingUsers) {
          this.inventory.inventory_users.map((user) => {
            if (this.selectedUserOptions[i] === user.users_permissions_user.id) {
              inventory_users.push(user.id)
              userAlreadyFound = true
            }
          })
        }
        if (!userAlreadyFound) {
          const new_user = {
            users_permissions_user: this.selectedUserOptions[i]
          }
          await DataService.postInventoryUser(new_user).then((response) => {
            inventory_users.push(response.data.id)
          }).catch((err) => {
            console.log(err)
          })
        }

      }
      return inventory_users
    },
    async addInventory() {
      const inventory_users = await this.createInventoryUsers(false)
      const inventory = {
        name: this.name,
        startDate: this.startDate,
        transferDate: new Date(),
        inventory_users: inventory_users,
        venue: this.getVenue.id,
        inventoryState: 'open',
        inventoryType: this.selectedType.value,
      }
      DataService.postInventory(inventory).then(() => {
        this.$emit('inventoryCreated')
        this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.successCreate"), variant: "success" });
      }
      ).catch((err) => {
        console.log(err)
        this.$store.commit("setAlertText", { text: this.$t("inventory.alerts.errorCreate"), variant: "danger" });
      })
    }
  }
}
</script>
<style scoped>
.startDatePicker {
  min-width: 220px;
}
</style>
